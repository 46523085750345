import { HttpClient } from "../HttpClient";
/**
 * * Post Asset File
 */
export const PostAssetFile = async (assetData) => {
    try {
        const { data } = await HttpClient.post("api/pexassets", assetData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};
