<template>
    <LayoutNew>
        <h1 class="text-center">Asset Upload</h1>
        <MDBCard id="AssetUploadComponent" fluid>
            <!-- form -->
            <MDBCardBody class="p-5 pb-0">
                <form method="post" action="#" id="" enctype="multipart/form-data" @submit.prevent="uploadAssets">
                    <div class="mb-4">
                        <h6>Add from URL(s)</h6>
                        <p>
                            Enter up to 100 URLs from any
                            <MDBTooltip v-model="tooltip" tag="a">
                                <template #reference>
                                    <strong class="text-primary">supported platforms</strong>
                                </template>
                                <template #tip>
                                    <h6>Supported Platforms</h6>
                                    <div style="display: grid; grid-template-columns: 1fr 1fr">
                                        <div>
                                            <div>Apple Podcasts</div>
                                            <div>Audiomack</div>
                                            <div>Audius</div>
                                            <div>Datpiff</div>
                                            <div>Dailymotion</div>
                                            <div>Facebook</div>
                                            <div>Gfycat</div>
                                            <div>Giphy</div>
                                            <div>Imgur</div>
                                            <div>Instagram</div>
                                            <div>MixCloud</div>
                                            <div>My Mixtapez</div>
                                            <div>Oddshot</div>
                                            <div>Ok</div>
                                            <div>Pearvideo</div>
                                            <div>Reddit</div>
                                        </div>
                                        <div>
                                            <div>Soundcloud</div>
                                            <div>Streamable</div>
                                            <div>Spotify Podcasts</div>
                                            <div>Tenor</div>
                                            <div>TikTok</div>
                                            <div>Triller</div>
                                            <div>Twitch</div>
                                            <div>Twitch Clips</div>
                                            <div>Twitter</div>
                                            <div>Vimeo</div>
                                            <div>VK</div>
                                            <div>Youku</div>
                                            <div>Youtube</div>
                                            <div>Pornhub</div>
                                            <div>xHamster</div>
                                            <div>XVideos</div>
                                            <div>Redtube</div>
                                        </div>
                                    </div>
                                </template>
                            </MDBTooltip>. Multiple URLs must be added on a separate line or separated
                            by comma. Valid URLs must start with either
                            <strong>http://</strong> or
                            <strong>https://</strong>
                        </p>
                        <textarea class="w-100" rows="4" v-model="urls"></textarea>
                    </div>
                    <div class="mt-4 mb-4">
                        <h6>Add from file(s)</h6>
                        <p>Upload up to 20 Files</p>
                        <input class="p-3 w-100 rounded-2" style="border: 1px solid gray" type="file" multiple="multiple" @change="onFileChanged($event)"
                            accept="video/*" capture />
                    </div>
                    <div class="mt-4 mb-4">
                        <h6>Choose Collection</h6>
                        <MDBSelect v-model:options="collectionList" v-model:selected="collectionSelected" filter
                            class="mb-2" />
                    </div>
                    <div class="mt-4 mb-4">
                        <h6>Production Date <span class="text-danger">*</span></h6>
                        <div class="d-flex align-items-center">
                            <MDBDatepicker v-model="productionDate" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" inline
                                label="Select a date" />
                        </div>
                    </div>
                    <div class="mt-4 mb-4">
                        <h6>Search Type</h6>
                        <MDBCheckbox label="Audio" v-model="isAudio" />
                        <MDBCheckbox label="Video" v-model="isVideo" />
                    </div>
                    <div class="mt-4 mb-4">
                        <h6>Tracking Preference</h6>
                        <MDBCheckbox label="Asset Tracking" v-model="isAssetTracking" />
                    </div>
                    <div class="mt-4 mb-4" v-if="isLoading">
                        <MDBAlert color="warning" static>
                            <h6>
                                <i class="fas fa-exclamation-triangle me-3"></i> Uploading
                                in-progress, please wait...
                            </h6>
                            <hr />
                            <strong>
                                Do not close, reload, or navigate away from this page
                            </strong>
                        </MDBAlert>
                    </div>
                    <MDBCardFooter class="py-3 gap-2 d-flex justify-content-end px-0">
                        <MDBBtn size="sm">Cancel</MDBBtn>
                        <template v-if="!isLoading">
                            <MDBBtn size="sm" type="submit" color="primary" :disabled="isUploadBtnDisabled">
                                Upload
                            </MDBBtn>
                        </template>
                        <template v-else>
                            <MDBBtn size="sm" color="primary" disabled>
                                <MDBSpinner class="loader" />
                            </MDBBtn>
                        </template>
                    </MDBCardFooter>
                </form>
            </MDBCardBody>
            <!-- toast -->
            <MDBToast v-model="toastSuccess" :delay="2000" autohide position="top-right" appendToBody stacking
                width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Success </template>
                Assets successfully uploaded.
            </MDBToast>
            <MDBToast v-model="toastError" :delay="2000" autohide position="top-right" appendToBody stacking
                width="350px" color="danger" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Failed </template>
                {{ toastErrorText }}
            </MDBToast>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBBtn,
    MDBSelect,
    MDBDatepicker,
    MDBToast,
    MDBSpinner,
    MDBCheckbox,
    MDBAlert,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { ref, computed, onMounted } from "vue";
import { PostAssetFile } from "@/services/Assets/PostAssetFile";
import { parseDateForBackend, isDateValid } from "@/helpers/parseDate";
import { GetCollectionsAll } from "@/services/Collections/GetCollectionsAll";
import { useTitle } from "@vueuse/core";

const files = ref([]);
const urls = ref("");
const productionDate = ref();
const tooltip = ref(false);

const onFileChanged = (e) => {
    if (e.target && e.target.files) {
        if (e.target.files.length > 20) {
            alert("Maximum allowable number of files exceeded.");
        }
        files.value = e.target.files;
    }
};

const uploadAssets = async () => {
    if (isUploadBtnDisabled.value) {
        return;
    }
    if (files.value.length > 20) {
        alert("Max 20 files only!");
        return;
    }
    isLoading.value = true;
    let formData = new FormData();
    Array.from(files.value).forEach((file) => {
        formData.append("Files", file);
    });
    formData.append("ProductionDate", parseDateForBackend(productionDate.value));
    formData.append("CollectionIds", [collectionSelected.value]);
    formData.append("AssetUrls", urls.value);
    formData.append("IsTracked", isAssetTracking.value);
    formData.append("MatchType", matchTypes.value);
    try {
        const response = await PostAssetFile(formData);
        if (response.errors) {
            if (response.errors?.productionDate) {
                toastErrorText.value = "Production Date is Invalid";
            } else {
                toastErrorText.value = "Upload Failed";
            }
            toastError.value = true;
        } else {
            toastSuccess.value = true;
        }
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const hasInputs = computed(() => {
    return files.value.length !== 0 || urls.value.length !== 0;
});

const matchTypes = computed(() => {
    let arr = [];
    if (isAudio.value) {
        arr.push("audio");
    }
    if (isVideo.value) {
        arr.push("video");
    }
    return arr;
});

const collectionList = ref([{ text: "Loading", value: "" }]);
const collectionSelected = ref("0");
const toastSuccess = ref(false);
const toastError = ref(false);
const toastErrorText = ref("Upload Failed");
const isLoading = ref(false);

const isUploadBtnDisabled = computed(() => {
    return (
        !hasInputs.value ||
        files.value.length > 20 ||
        !productionDate.value ||
        productionDate.value === "" ||
        !isDateValid(productionDate.value)
    );
});

onMounted(async () => {
    useTitle("Asset Upload | CreatorShield");
    const response = await GetCollectionsAll();
    collectionList.value = response.map((collection) => {
        return {
            ...collection,
            text: `${collection.title}`,
            value: `${collection.collectionIdString}`,
        };
    });
    collectionList.value.unshift({
        text: "Default Collection",
        value: 0,
    });
});

const isAudio = ref(false);
const isVideo = ref(true);
const isAssetTracking = ref(true);
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.btn-outline-primary {
  border-color: var(--accent);
  color: var(--accent);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

#AssetUploadComponent {
    color: var(--accent);
}

#AssetUploadComponent .title {
    margin: 0;
    color: var(--accent);
}

#AssetUploadComponent .actions .solid {
    background-color: var(--accent);
    border-color: var(--accent);
    color: white;
}

#AssetUploadComponent .actions .solid:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

.loader {
    width: 1rem;
    height: 1rem;
}
</style>